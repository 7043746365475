body {
  height: 100%;
  width: 100%;
}

.App-header {
  background-color: #464c58;
  display: flex;
  margin-left: 20px;
  padding: 10px;
  margin-top: 20px;
  font-size: calc(10px + 2vmin);
  color: white;
}

hr {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-style: groove;
  border-width: 2px;
}

a {
  line-height: 20px;
  padding: 2px;
  padding-right: 5px;
}
a:hover {
  color: #D22630;
}